import { ReactNode, useContext, createContext, useMemo } from 'react';
import { useTimeFilter } from 'dashboard/components/TimeFilterProvider';
import useParamsItem from 'dashboard/hooks/useParamsItem';
import useAuthApi from 'app/hooks/useApi';
import { BatteryType } from 'app/constants/battery';
import { AlertLevel, AlertType } from 'alerts/constants/alerts';
import { AlertFilter } from 'app/constants/alerts';
import * as R from 'ramda';

export type AlertLevelCounter = {
  ongoing: number;
  past: number;
}

export type AlertTypeCounter = {
  malfunction: AlertLevelCounter;
  critical: AlertLevelCounter;
  unknown: AlertLevelCounter;
}

export type AlertCounters = {
  cellimbalance: AlertTypeCounter;
  overheating: AlertTypeCounter;
  overvoltage: AlertTypeCounter;
  undervoltage: AlertTypeCounter;
}

type Context = {
  counters: AlertCounters;
  count_alerts_by_state: (state: AlertFilter) => number,
  count_alerts_by_severity: (
    severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION
  ) => number,
  count_alerts_by_severity_and_state: (
    severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION,
    state: AlertFilter
    ) => number,
    count_alerts_by_type_and_severity: (
      kind: AlertType.ci | AlertType.overTemp | AlertType.overVolt | AlertType.underVolt,
      severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION
      ) => number,
    count_alerts_by_severity_and_type_and_state: (
      severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION,
      kind: AlertType.ci | AlertType.overTemp | AlertType.overVolt | AlertType.underVolt,
      state: AlertFilter,
  ) => number,
};

export const default_counters = {
  count_alerts_by_state: () => 0,
  count_alerts_by_severity: () => 0,
  count_alerts_by_severity_and_state: () => 0,
  count_alerts_by_type_and_severity: () => 0,
  count_alerts_by_severity_and_type_and_state: () => 0,
  counters : {
    cellimbalance: {
      malfunction: {
        ongoing: 0,
        past: 0
      },
      critical: {
        ongoing: 0,
        past: 0
      },
      unknown: {
        ongoing: 0,
        past: 0
      }
    },
    overheating: {
      malfunction: {
        ongoing: 0,
        past: 0
      },
      critical: {
        ongoing: 0,
        past: 0
      },
      unknown: {
        ongoing: 0,
        past: 0
      }
    },
    overvoltage: {
      malfunction: {
        ongoing: 0,
        past: 0
      },
      critical: {
        ongoing: 0,
        past: 0
      },
      unknown: {
        ongoing: 0,
        past: 0
      }
    },
    undervoltage: {
      malfunction: {
        ongoing: 0,
        past: 0
      },
      critical: {
        ongoing: 0,
        past: 0
      },
      unknown: {
        ongoing: 0,
        past: 0
      }
    }
  }
}

export const count_alerts_by_state = (alert_counters: AlertCounters, state: AlertFilter ) : number => Object
  .values(alert_counters)
  .map(kind => Object.values(kind))
  .flat(1)
  .map(severity => ({
    [AlertFilter.ALL]: severity.ongoing + severity.past,
    [AlertFilter.ONGOING]: severity.ongoing,
    [AlertFilter.PAST]: severity.past
  }[state]))
  .reduce((sum, current) => sum + current, 0)

export const count_alerts_by_severity = (
  counters: AlertCounters,
  severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION
) : number => Object
  .values(counters)
  .map(kind => ({
    [AlertLevel.CRITICAL]: kind.critical,
    [AlertLevel.MALFUNCTION]: kind.malfunction,
    [AlertLevel.UNKNOWN]: kind.unknown
  }[severity]))
  .map(s => s.ongoing + s.past)
  .reduce((sum, current) => sum + current, 0)

export const count_alerts_by_severity_and_state = (
  counters: AlertCounters,
  severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION,
  state: AlertFilter
) : number => Object
  .values(counters)
  .map(kind => ({
    [AlertLevel.CRITICAL]: kind.critical,
    [AlertLevel.MALFUNCTION]: kind.malfunction,
    [AlertLevel.UNKNOWN]: kind.unknown
  }[severity]))
  .map(kind_severity => ({
    [AlertFilter.ALL]: kind_severity.ongoing + kind_severity.past,
    [AlertFilter.ONGOING]: kind_severity.ongoing,
    [AlertFilter.PAST]: kind_severity.past
  }[state]))
  .reduce((sum, current) => sum + current, 0)

export const count_alerts_by_type_and_severity = (
  counters: AlertCounters,
  kind: AlertType.ci | AlertType.overTemp | AlertType.overVolt | AlertType.underVolt,
  severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION,
) : number => {
  const type_counters = {
    [AlertType.ci] : counters.cellimbalance,
    [AlertType.overTemp] : counters.overheating,
    [AlertType.overVolt] : counters.overvoltage,
    [AlertType.underVolt] : counters.undervoltage
  }[kind]
  
  const severity_counters = {
    [AlertLevel.CRITICAL] : type_counters.critical,
    [AlertLevel.MALFUNCTION] : type_counters.malfunction
  }[severity]

  return severity_counters.ongoing + severity_counters.past 
}

export const count_alerts_by_severity_and_type_and_state = (
  counters: AlertCounters,
  severity: AlertLevel.CRITICAL | AlertLevel.MALFUNCTION,
  kind: AlertType.ci | AlertType.overTemp | AlertType.overVolt | AlertType.underVolt,
  state: AlertFilter,
) : number => {
  const type_counters = {
    [AlertType.ci] : counters.cellimbalance,
    [AlertType.overTemp] : counters.overheating,
    [AlertType.overVolt] : counters.overvoltage,
    [AlertType.underVolt] : counters.undervoltage
  }[kind]
  
  const severity_counters = {
    [AlertLevel.CRITICAL] : type_counters.critical,
    [AlertLevel.MALFUNCTION] : type_counters.malfunction
  }[severity]

  const type_severity_counters = {
    [AlertFilter.ONGOING]: severity_counters.ongoing,
    [AlertFilter.PAST]: severity_counters.past,
    [AlertFilter.ALL]: severity_counters.ongoing + severity_counters.past,
  }[state]

  return type_severity_counters;
}

const StateContext = createContext<Context>(default_counters);
StateContext.displayName = "AlertCounters"

interface IProps {
  children: ReactNode;
}

function AlertCountersProvider({ children }: IProps) {
  const { hierarchyid, id, type : nodetype } = useParamsItem();
  const { selectedMasterPeriod } = useTimeFilter();

  const { data } = useAuthApi(
    `alert-counters-${hierarchyid}-${selectedMasterPeriod.value}`,
    `dyndata/${nodetype as BatteryType}/${id}/alerts/counters?${selectedMasterPeriod.startStop}`,
    {
      enabled: !!id && !!selectedMasterPeriod.startStop,
    },
  );

  const context = useMemo(() => (data?.data && {
    counters: data.data,
    count_alerts_by_state: R.curry(count_alerts_by_state)(data.data),
    count_alerts_by_severity: R.curry(count_alerts_by_severity)(data.data),
    count_alerts_by_severity_and_state: R.curry(count_alerts_by_severity_and_state)(data.data),
    count_alerts_by_type_and_severity: R.curry(count_alerts_by_type_and_severity)(data.data),
    count_alerts_by_severity_and_type_and_state: R.curry(count_alerts_by_severity_and_type_and_state)(data.data),
  } || default_counters ), [data]);

  return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
}

export const useAlertCounters = () => useContext(StateContext);

export default AlertCountersProvider;
