import { ReactNode, useContext, createContext, useState, useMemo, useCallback, Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AlertType, AlertLevel } from 'alerts/constants/alerts';
import { AlertFilter } from 'app/constants/alerts';

export type AlertFilterType = { type: AlertType; level: AlertLevel };
export type AlertLevelsFilter = {[K in AlertType]?: Set<AlertLevel>};

export type Pagination = { page: number; items_per_page: number };
export type Sort = { field: string, ascending: boolean };

type Context = {
  opened: boolean;
  idFilter: string | null;
  open: (alert: AlertFilterType[] | null, id: string | null, filter?: AlertFilter) => void;
  close: () => void;
  toggle: () => void;

  // alertFilter and alertLevelsFilter
  // hold the same value in different data structures
  // please use alertLevelsFilter and setAlertLevelsFilter
  alertFilter: AlertFilterType[]; // deprecated
  setAlertFilter: Dispatch<SetStateAction<AlertFilterType[]>>; // deprecated
  alertLevelsFilter: AlertLevelsFilter;
  setAlertLevelsFilter: Dispatch<SetStateAction<AlertLevelsFilter>>;

  selectedFilter: AlertFilter; // selects the visible alert state
  setSelectedFilter: Dispatch<SetStateAction<AlertFilter>>;
  
  pagination: Pagination,
  setPageNumber: (page_num: number) => void,
  
  sort: Sort,
  setSort: (sort: Sort) => void,
};

interface IProps {
  children: ReactNode;
}

const defaultContext = {
  opened: false,
  
  alertFilter: [],
  setAlertFilter: () => {},
  alertLevelsFilter: {},
  setAlertLevelsFilter: () => {},

  idFilter: null,
  selectedFilter: AlertFilter.ONGOING,
  open: () => {},
  close: () => {},
  toggle: () => {},
  setSelectedFilter: () => {},
  pagination: { page: 0, items_per_page: 12 },
  setPageNumber: () => {},
  sort: {
    field: "start",
    ascending: false
  } as Sort,
  setSort: () => {}
};


// export function alertLevelFilterToSearchParams(
//   alertLevelsFilter: AlertLevelsFilter, 
//   searchParams: URLSearchParams = new URLSearchParams()
// ): URLSearchParams {
//   if (Object.keys(alertLevelsFilter).length === 0) {
//     Array.from(searchParams.keys()).filter(key => key.startsWith('type')).forEach(key => searchParams.delete(key));
//     return searchParams;
//   }
//   return Object.entries(alertLevelsFilter)
//     .reduce((queryParams, [alert_type, levels]) => {
//       if (levels.size === 0) {
//         queryParams.delete(`type${alert_type}__in`);
//       } else {
//         queryParams.set(`type${alert_type}__in`, Array.from(levels).join(','));
//       }
//       return queryParams;
//     }, searchParams)
// }

// export function parseAlertLevelFilter(searchParams: URLSearchParams) {
//   return Array.from(searchParams.entries())
//     .filter(([key]) => key.startsWith('type'))
//     .reduce((filters, [key, value]) => (
//       {
//         ...filters,
//         [Number(key.match(/\d+/))]: new Set(value.split(',').map(Number)),
//       }
//     ), {} as AlertLevelsFilter);
// }


const StateContext = createContext<Context>(defaultContext);
StateContext.displayName = "StickyBottomAlertsBar"

function StickyBottomAlertsBarProvider({ children }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.get('alarmsOpen');
  const searchParamsSelectedFilter = searchParams.get('alarmsFilter');

  const [opened, open] = useState<boolean>(Boolean(isOpen));
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    items_per_page: 12
  });
  const [alertFilter, setAlertFilter] = useState<AlertFilterType[]>(defaultContext.alertFilter);
  const [alertLevelsFilter, setAlertLevelsFilter] = useState<AlertLevelsFilter>(defaultContext.alertLevelsFilter);
  const [idFilter, setIdFilter] = useState<string | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<AlertFilter>(
    searchParamsSelectedFilter
    ? searchParamsSelectedFilter as AlertFilter
    : AlertFilter.ONGOING
  );
  
  const [sort, setSort] = useState(defaultContext.sort);

  useEffect(() => {
    setAlertLevelsFilter(alertFilter.reduce((levelFilters, filter) => {
      const newLevels = levelFilters[filter.type] || new Set();
      newLevels.add(filter.level)
      return  {
        ...levelFilters,
        [filter.type] : newLevels
      }
    }, {} as AlertLevelsFilter));
    // setSearchParams(alertLevelFilterToSearchParams(alertLevelsFilter, searchParams));
  }, [alertFilter]);

  useEffect(() => {
    const requestedAlarmsFilter = searchParams.get('alarmsFilter') as AlertFilter;
    if (!requestedAlarmsFilter || !Object.values(AlertFilter).includes(requestedAlarmsFilter)) return;
    setSelectedFilter(requestedAlarmsFilter);
  }, [searchParams]);

  const openCallback = useCallback(
    (alert: AlertFilterType[] | null = null, id: string | null = null, filter: AlertFilter = AlertFilter.ONGOING) => {
      open(true);
      searchParams.set('alarmsOpen', 'true');
      setSearchParams(searchParams);
      setAlertFilter(alert || []);
      setIdFilter(id);
      setSelectedFilter(filter);
    },
    [searchParams, setSearchParams],
  );

  const closeCallback = useCallback(() => {
    open(false);
    setAlertLevelsFilter({});
    setIdFilter(null);
  }, []);

  const toggleCallback = useCallback(() => {
    if (opened) {
      searchParams.delete('alarmsOpen');
      searchParams.delete('alarmsFilter');
      setSearchParams(searchParams);
      closeCallback();
    } else {
      searchParams.set('alarmsOpen', 'true');
      setSearchParams(searchParams);
      openCallback();
    }
  }, [opened, closeCallback, openCallback, searchParams, setSearchParams]);

  const setPageNumber = useCallback((page_number: number) => {
    setPagination({
      ...pagination,
      page: page_number
    })
  }, [pagination])

  const setAlertFilterCallback = useCallback((filters: SetStateAction<AlertFilterType[]>) => {
    setPageNumber(0);
    setAlertFilter(filters);
  }, [setPageNumber, setAlertFilter]);

  const setAlertStateFilterCallback = useCallback((filter: SetStateAction<AlertFilter>) => {
    setPageNumber(0);
    setSelectedFilter(filter);
  }, [setPageNumber, setSelectedFilter]);

  const context = useMemo(() => ({
    opened,
    open: openCallback,
    close: closeCallback,
    toggle: toggleCallback,
    alertFilter,
    setAlertFilter: setAlertFilterCallback,
    alertLevelsFilter,
    setAlertLevelsFilter,
    idFilter,
    selectedFilter,
    setSelectedFilter: setAlertStateFilterCallback,
    pagination,
    setPageNumber,
    sort,
    setSort
  }), [
    opened,
    openCallback,
    closeCallback,
    toggleCallback,
    alertFilter,
    setAlertFilterCallback,
    alertLevelsFilter,
    setAlertLevelsFilter,
    idFilter,
    selectedFilter,
    setAlertStateFilterCallback,
    pagination,
    setPageNumber,
    sort,
    setSort
  ]);

  return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
}

export const useStickyBottomAlerts = () => useContext(StateContext);

export default StickyBottomAlertsBarProvider;
